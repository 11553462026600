<template>
  <div>
    <div>
      <span class="label">Будинок</span>
      <v-autocomplete
        v-model="house"
        :items="houses"
        :item-title="getHouseText"
        item-value="HouseId"
        :loading="$loading.isLoading('getHouses')"
        :readonly="!houseMoniker"
        placeholder="оберіть зі списку"
        return-object
        no-filter
        dense
        hide-details
        @update:search="getHouses">
        <template #item="{ item, props }">
          <v-list-item v-bind="props" :title="getHouseTitleText(item.raw)">
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue'
import { House } from '@/utils/types'
import { addressIndexMask } from '@/utils/masks.js'
import { debounce } from 'lodash'
import { handleAsync } from 'best-modules/plugins'
import { getHouses as getHousesRequest } from '@/utils/addressApi'
import { concatRegex, CYRILLIC, SPACE } from 'best-modules/utils/regExp'
import { getHouseTemplate } from '../address'

export default {
  name: 'House',
  emits: ['update:model-value'],
  props: {
    modelValue: {
      type: Object as PropType<House>,
      required: true,
    },
    houseMoniker: {
      type: String as PropType<string>,
    },
  },
  data: () => {
    return {
      mask: {
        mask: 'A'.repeat(100),
        tokens: {
          A: { pattern: concatRegex(CYRILLIC, SPACE) },
        },
      },
      addressIndexMask,
    }
  },
  setup(props, { emit }) {
    const house = computed<House | null>({
      get() {
        if (props.modelValue.HouseId) {
          return props.modelValue
        }
        return null
      },
      set(v) {
        const h = v || getHouseTemplate()
        h.apartmentNum = props.modelValue.apartmentNum
        emit('update:model-value', h)
      },
    })
    const houses = ref<House[]>([])

    const getHouseText = (h: House | null): string => {
      if (h) {
        const houseNum = h?.HouseNum?.replace(h?.HouseNumAdd, '').trim() || ''
        return `${houseNum}${h?.HouseNumAdd || ''}`.trim()
      } else {
        return ''
      }
    }
    const getHouseTitleText = (h: House): string => {
      const houseNum = h.HouseNum?.replace(h.HouseNumAdd, '').trim() || ''
      const index = h.Index_ ? `, Індекс ${h.Index_}` : null
      return [houseNum, h?.HouseNumAdd, index].join('')
    }

    const getHouses = debounce((search: string) => {
      return handleAsync('getHouses', async () => {
        if (
          search &&
          search !== getHouseText(house.value) &&
          props.houseMoniker
        ) {
          houses.value = await getHousesRequest(search, props.houseMoniker)
        }
      })
    }, 500)

    return { house, houses, getHouses, getHouseText, getHouseTitleText }
  },
}
</script>

<style scoped></style>
